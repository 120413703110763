<template>
	<div id="mainpage" class="flex flex-col min-h-screen thank-you">
		<SiteHeader />

		<header class="relative w-full">
			<!--Main-Banner-->
			<div class="container h-full">
				<div class="flex flex-col text-center text-white uppercase mt-18 md:text-left lg:mt-28 xl:mt-36">
					<div class="text-6xl font-normal leading-none font-GildaDisplay md:text-12xl lg:text-22xl">Thank You</div>
					<div class="mt-3 mb-8 text-xl font-light leading-tight font-JosefinSans lg:text-6xl">Your Message Has <span class="inline-block">Been Sent!</span></div>
					<div class="block tracking-wides font-JosefinSans">
							Thank you for your kind interest in HOH Law Corporation.<br>We will attend to your request as soon as possible.
					</div>
					<div class="flex flex-col mt-16 mb-20 md:mt-10 md:flex-row">
						<a href="tel:+6565534800" class="flex items-center justify-center px-6 mx-auto mb-5 text-xs leading-none border border-white rounded-full font-JosefinSans w-65 h-13 md:mx-0 md:mr-5 md:mb-0 lg:text-tiny">
							<div class="mr-2 font-semibold tracking-widest text-white uppercase">call us at</div>
							<span class="block text-base font-semibold tracking-widest text-siteblue">6553 4800</span>
						</a>
						<a href="/" class="flex items-center justify-center px-6 mx-auto text-xs font-semibold leading-none tracking-widest text-white uppercase transition-all duration-200 rounded-full w-65 h-13 bg-siteblue font-JosefinSans hover:bg-sitegreen-600 md:mx-0 lg:text-tiny"><span>Return Home</span></a>
					</div>
				</div>
			</div>
			<!--Main-Banner End-->
		</header>

		<SiteFooter />

	</div>
</template>

<script>
// @ is an alias to /src
import SiteHeader from '@/components/SiteHeader.vue'
import SiteFooter from '@/components/SiteFooter.vue'

export default {
	name: 'thank-you',
	components: {
		SiteHeader,
		SiteFooter,
	},
	mounted () {
		// Event snippet for Conversion - Thank you Page conversion page
		// this.$gtag.event( 'conversion', { 'send_to': 'AW-349698048/VovhCNDRvsYCEIDw36YB' } );
	},
}
</script>
